<template>
  <div class="h-full">
    <div class="bg-mask-delete" v-if="isShowDeleteAds"></div>

    <div class="mx-2 scrollbar-hide h-full overflow-y-scroll" v-show="!isShowCarousel">
      <div class="estate_img" :style="{backgroundImage: `url(${data.thumbnail ? baseURL + data.thumbnail.link : ' '})` }">
        <div class="estate_img__header">
          <button class="back-btn" @click="()=> this.$router.go(-1)">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
              <defs>
                <filter id="Rectangle_3158" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
                  <feOffset dy="3" input="SourceAlpha"/>
                  <feGaussianBlur stdDeviation="3" result="blur"/>
                  <feFlood flood-opacity="0.161"/>
                  <feComposite operator="in" in2="blur"/>
                  <feComposite in="SourceGraphic"/>
                </filter>
              </defs>
              <g id="Group_12961" data-name="Group 12961" transform="translate(-308 -28)">
                <g id="Group_4717" data-name="Group 4717" transform="translate(317 33.547)">
                  <g id="Group_4426" data-name="Group 4426" transform="translate(0 0.452)">
                    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_3158)">
                      <rect id="Rectangle_3158-2" data-name="Rectangle 3158" width="25" height="25" rx="12.5" transform="translate(9 6)" fill="#ff7400"/>
                    </g>
                  </g>
                </g>
                <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(317.705 34.376)" fill="#fff"/>
              </g>
            </svg>

          </button>
          <div class="estate_img__logo cursor-pointer" @click="()=> this.$router.push({name : 'RealEstatePageRealEstate' ,params :{ id : data.real_estate_id}})">
            <img :src="baseURL + data.realestate.logo" alt="">
            <span>{{data.advertisement_type}}</span>

          </div>
        </div>
        <div class="estate_img__body">
          <div class="estate_img__data">
            <h1>{{data.title}}</h1>
            <address>{{data.city + ','+ data.area + ',' + data.address }}</address>
            <span>{{data.created_at}}</span>
          </div>
          <div class="estate_img__carousel">
            <div class="gallery_slider">
              <span @click="isShowCarousel = true" class="cursor-pointer"><img v-if="this.countMedia >= 1" :src="baseURL + data.media[0].link" alt=""></span>
              <span @click="isShowCarousel = true" class="cursor-pointer"><img v-if="this.countMedia >= 2" :src="baseURL +  data.media[1].link" alt=""></span>
              <span v-if="this.countMedia >= 3" @click="isShowCarousel = true" class="cursor-pointer"><img :src="baseURL +  data.media[2].link" alt=""> <span class="count_slider">{{ countMedia - 2 + '+' }}</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4">
        <div v-if="data.advertisement_type_en === 'rent' || data.advertisement_type_en === 'mortgageRent' ">
          <p class="text-white text-left mt-4"> ودیعه :   {{( '' +data.options.downPayment).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} <span>تومان</span> </p>
          <p class="text-white text-left mt-4">اجاره  :   {{( '' +data.options.rent_price).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} <span>تومان</span> </p>
        </div>
        <div v-else>
          <p class="text-white text-left mt-4">{{( '' +data.options.price).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} <span>تومان</span></p>
        </div>
        <div>
          <h3 class="text-white mb-3">توضیحات</h3>
          <div class="flex justify-between flex-wrap">
            <div class="icon-box">
            <span class="ml-2 bg-icon">
              <svg id="Group_12653" data-name="Group 12653" xmlns="http://www.w3.org/2000/svg" width="19.356" height="12.302" viewBox="0 0 19.356 12.302">
  <g id="Group_12650" data-name="Group 12650">
    <g id="bed">
      <path id="Path_9" data-name="Path 9" d="M58.942,91.707a1.834,1.834,0,0,0-1.832-1.832H53a1.832,1.832,0,0,0-1.53.825,1.832,1.832,0,0,0-1.53-.825H45.826a1.834,1.834,0,0,0-1.832,1.832v.084h-.459V90a2.013,2.013,0,0,1,2.011-2.011H57.39A2.013,2.013,0,0,1,59.4,90v1.787h-.459v-.084Z" transform="translate(-41.79 -87.994)" fill="#ff7400"/>
      <path id="Path_10" data-name="Path 10" d="M.77,204.076H18.586a.771.771,0,0,1,.77.77v4.47a.771.771,0,0,1-.77.77h-.321l.379,1.069a.428.428,0,1,1-.806.286l-.48-1.355H2l-.48,1.355a.428.428,0,0,1-.806-.286l.379-1.069H.77a.771.771,0,0,1-.77-.77v-4.47A.771.771,0,0,1,.77,204.076Z" transform="translate(0 -199.423)" fill="#ff7400"/>
      <path id="Path_11" data-name="Path 11" d="M260.352,156.262a.978.978,0,0,1,.976.976v.084h-6.066v-.084a.978.978,0,0,1,.976-.976Z" transform="translate(-252.204 -153.526)" fill="#ff7400"/>
      <path id="Path_12" data-name="Path 12" d="M81.414,156.262a.978.978,0,0,1,.976.976v.084H76.325v-.084a.978.978,0,0,1,.977-.976Z" transform="translate(-66.094 -153.526)" fill="#ff7400"/>
    </g>
  </g>
</svg>
            </span>
              <span class="text-white text-xs ">{{data.options.number_of_bedrooms}} اتاق خواب</span>
            </div>
            <div class="icon-box">
            <span class="ml-2 bg-icon">
<svg id="Group_12649" data-name="Group 12649" xmlns="http://www.w3.org/2000/svg" width="23.017" height="12.302" viewBox="0 0 23.017 13.702">
  <g id="bath">
    <path id="Path_1" data-name="Path 1" d="M168.361,139.67a.361.361,0,0,0,.361-.361v-1.267a.361.361,0,1,0-.723,0v1.267A.361.361,0,0,0,168.361,139.67Z" transform="translate(-161.033 -136.932)" fill="#ff7400"/>
    <path id="Path_2" data-name="Path 2" d="M330.361,141.333a.361.361,0,0,0,.361-.361v-1.5a.361.361,0,0,0-.723,0v1.5a.361.361,0,0,0,.361.361Z" transform="translate(-314.672 -138.292)" fill="#ff7400"/>
    <path id="Path_3" data-name="Path 3" d="M222.361,143.643a.361.361,0,0,0,.361-.361v-1.806a.361.361,0,1,0-.723,0v1.806A.361.361,0,0,0,222.361,143.643Z" transform="translate(-212.246 -140.189)" fill="#ff7400"/>
    <path id="Path_4" data-name="Path 4" d="M276.361,125.93a.361.361,0,0,0,.361-.361v-2.015a.361.361,0,1,0-.723,0v2.015A.361.361,0,0,0,276.361,125.93Z" transform="translate(-263.459 -123.193)" fill="#ff7400"/>
    <path id="Path_5" data-name="Path 5" d="M134.377,380.449A.659.659,0,0,0,135,380h-1.243A.659.659,0,0,0,134.377,380.449Z" transform="translate(-128.556 -366.747)" fill="#ff7400"/>
    <path id="Path_6" data-name="Path 6" d="M354.79,380.449a.659.659,0,0,0,.621-.449h-1.242A.659.659,0,0,0,354.79,380.449Z" transform="translate(-337.594 -366.747)" fill="#ff7400"/>
    <path id="Path_7" data-name="Path 7" d="M63.814,244.378a1.819,1.819,0,0,0,1.7,1.176h13.45a1.819,1.819,0,0,0,1.7-1.176L82.24,239h-20Z" transform="translate(-60.732 -233.023)" fill="#ff7400"/>
    <path id="Path_8" data-name="Path 8" d="M55.759,214h-22.5a.251.251,0,0,0-.258.249v.318H56.017v-.318a.251.251,0,0,0-.258-.249Z" transform="translate(-33 -209.312)" fill="#ff7400"/>
  </g>
</svg>
            </span>
              <span class="text-white text-xs ">{{data.options.bathroom}} حمام</span>
            </div>
            <div class="icon-box">
            <span class="ml-2 bg-icon">
<svg xmlns="http://www.w3.org/2000/svg" width="17.966" height="12.302" viewBox="0 0 17.966 15.657">
  <g id="Group_12662" data-name="Group 12662" transform="translate(0 0)">
    <g id="car-fill-from-frontal-view">
      <path id="Path_13" data-name="Path 13" d="M747.9,776.127v-1.065h2.7v1.065A1.354,1.354,0,1,1,747.9,776.127Z" transform="translate(-746.462 -761.891)" fill="#ff7400"/>
      <path id="Path_14" data-name="Path 14" d="M77.7,776.127v-1.065h2.693v1.065A1.348,1.348,0,1,1,77.7,776.127Z" transform="translate(-63.863 -761.891)" fill="#ff7400"/>
      <path id="Path_15" data-name="Path 15" d="M0,73.415V69.682a2.078,2.078,0,0,1,1.63-2.065l1.473-3.8A2.007,2.007,0,0,1,5.12,62.462h7.739a2.017,2.017,0,0,1,2.017,1.353l1.473,3.8a2.069,2.069,0,0,1,1.617,2.065v3.734a1.11,1.11,0,0,1-1.109,1.109H1.109A1.109,1.109,0,0,1,0,73.415Zm14.953-1.628h.007a1.281,1.281,0,0,0,.007-2.56h-.022a1.281,1.281,0,0,0,.007,2.56Zm-5.97-4.2h4.623a.568.568,0,0,0,.523-.789l-.776-1.835c-.116-.29-.342-.73-.834-.737H5.464c-.484.007-.606.279-.834.737l-.776,1.835a.568.568,0,0,0,.523.789ZM3.1,71.879h.007a1.28,1.28,0,0,0,.006-2.56H3.09a1.281,1.281,0,0,0,.009,2.56Z" transform="translate(0 -62.462)" fill="#ff7400"/>
    </g>
  </g>
</svg>
            </span>
              <span class="text-white text-xs "> {{data.options.number_of_parking}}  پارکینگ </span>
            </div>
            <div class="icon-box">
            <span class="ml-2 py-4 px-3">
<svg xmlns="http://www.w3.org/2000/svg" width="18.889" height="17" viewBox="0 0 18.889 17">
  <path id="ic_business_24px" d="M11.444,6.778V3h9.444V20H2V6.778Zm5.667,11.333H19V16.222H17.111Zm0-3.778H19V12.444H17.111Zm0-3.778H19V8.667H17.111Zm0-3.778H19V4.889H17.111ZM13.333,18.111h1.889V16.222H13.333Zm0-3.778h1.889V12.444H13.333Zm0-3.778h1.889V8.667H13.333Zm0-3.778h1.889V4.889H13.333ZM3.889,18.111h7.556V16.222H9.556V14.333h1.889V12.444H9.556V10.556h1.889V8.667H3.889Zm1.889-7.556H7.667v1.889H5.778Zm0,3.778H7.667v1.889H5.778Z" transform="translate(-2 -3)" fill="#ff7400"/>
</svg>
            </span>
              <span class="text-white text-xs ">{{data.options.age}} سال</span>
            </div>
            <div class="icon-box">
            <span class="ml-2 py-4 px-3">
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <path id="ic_format_shapes_24px" d="M19,5.909V1H14.091V2.636H5.909V1H1V5.909H2.636v8.182H1V19H5.909V17.364h8.182V19H19V14.091H17.364V5.909ZM2.636,2.636H4.273V4.273H2.636ZM4.273,17.364H2.636V15.727H4.273Zm9.818-1.636H5.909V14.091H4.273V5.909H5.909V4.273h8.182V5.909h1.636v8.182H14.091Zm3.273,1.636H15.727V15.727h1.636ZM15.727,4.273V2.636h1.636V4.273Z" transform="translate(-1 -1)" fill="#ff7400"/>
</svg>
            </span>
              <span class="text-white text-xs ">{{data.options.area}} متر</span>
            </div>
            <div class="icon-box">
            <span class="ml-2 py-4 px-3">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10">
  <path id="ic_equalizer_24px" d="M6,0h4V16H6ZM0,0H4V8H0Z" transform="translate(16) rotate(90)" fill="#ff7400"/>
</svg>
            </span>
              <span class="text-white text-xs ">{{data.options.number_of_washroom}} سرویس بهداشتی</span>
            </div>

          </div>
        </div>
        <h3 class="text-white border-b pb-2 border-opacity-30 border-white">مشخصات</h3>
        <ul class="specifications-ul">
          <li>
            <span><strong>نوع کاربری : </strong> {{data.usage.toString()}} </span>
          </li>
          <li>
            <span><strong>نوع ملک : </strong>{{data.house_type}}</span>
          </li>
          <li>
            <span><strong>تعداد طبقات : </strong>{{data.options.numbers_of_floors}}</span>
          </li>
          <li>
            <span><strong>تعداد واحد در هر طبقه : </strong>{{data.options.number_of_units_per_floor}}</span>
          </li>
          <li>
            <span><strong>تعداد آشپزخانه : </strong>{{data.options.number_of_kitchens}}</span>
          </li>
          <li>
            <span><strong>تعداد مستر : </strong>{{data.options.number_of_master_rooms}}</span>
          </li>
          <li>
            <span><strong>تعداد سرویس فرنگی : </strong>{{data.options.number_of_sit_down_service}}</span>
          </li>
          <li>
            <span><strong>جهت نما : </strong>{{data.options.facade}}</span>
          </li>
        </ul>
        <h3 class="text-white mt-8 border-b pb-2 border-opacity-30 border-white">امکانات ویژه</h3>
        <div class="special_list">
          <span v-for="item in data.options.otherFeatures['sepcialFeatures']">{{ item }}</span>
        </div>
        <!--      <h3 class="text-white mt-8 border-b pb-2 border-opacity-30 border-white">امکانات مبله</h3>
              <ul class="specifications-ul">
                <li>
                  <span><strong>نوع کاربری : </strong> مسکونی</span>
                </li>
                <li>
                  <span><strong>نوع ملک : </strong>مسکونی</span>
                </li>
                <li>
                  <span><strong>تعداد سرویس : </strong>مسکونی</span>
                </li>
              </ul>-->
        <h3 class="text-white mt-8 border-b pb-2 border-opacity-30 border-white">توضیحات</h3>
        <div class="mt-4">
          <p class="text-white text-sm">{{data.description}}</p>
        </div>
        <h3 class="text-white mt-8 border-b pb-2 border-opacity-30 border-white">آدرس</h3>
        <div class="mt-4" style="width: 100%; height: 200px;">
          <mapir class="rounded-map" :apiKey="mapToken" :mapStyle="this.$store.state.map.options.style" :zoom="14" :center="marker">
            <mapMarker
                :coordinates.sync="marker"
                color="blue"
                :draggable="false"
            >
              <template slot="marker">
                <img class="w-14 h-14 rounded-full" src="../../assets/images/icons/location-pin.svg" alt="">
              </template>
            </mapMarker>
          </mapir>
        </div>
        <div class="divider-orange"></div>
        <div>
          <div

              class="bongah-box z-0">
            <div class="image-section">
              <img class="image-product" v-if="data.realestate.media.length >= 1" :src="baseURL + data.realestate.media[data.realestate.media.length - 1].file" alt="">
              <img class="image-product" v-else src="../../assets/images/digitalmarket.png" alt="">

            </div>
            <div class="detail-section">
              <div class="logo">
                <router-link :to="{name : 'RealEstatePageCustomer' , params :{ id : data.realestate.id}}">
                  <img :src="baseURL + data.realestate.logo" alt="">
                </router-link>

              </div>
              <p> نام بنگاه : <span>{{data.realestate.name}}</span> </p>
              <div class="flex justify-between gap-2">
                <a target="_blank" :href="`tel:${data.realestate.telephone}`" class="bongah-filter"><svg xmlns="http://www.w3.org/2000/svg" width="20.879" height="20.877" viewBox="0 0 20.879 20.877">
                  <path id="Icon_ionic-ios-call" data-name="Icon ionic-ios-call" d="M24.766,20.85A17.537,17.537,0,0,0,21.1,18.4c-1.1-.527-1.5-.516-2.277.043-.647.467-1.065.9-1.81.739a10.8,10.8,0,0,1-3.636-2.69,10.72,10.72,0,0,1-2.69-3.636c-.158-.75.277-1.163.739-1.81.56-.777.576-1.179.043-2.277A17.19,17.19,0,0,0,9.02,5.1c-.8-.8-.978-.625-1.419-.467a8.073,8.073,0,0,0-1.3.69,3.919,3.919,0,0,0-1.56,1.647c-.31.669-.669,1.913,1.158,5.164a28.812,28.812,0,0,0,5.066,6.756h0l.005.005.005.005h0a28.925,28.925,0,0,0,6.756,5.066c3.25,1.826,4.495,1.468,5.164,1.158a3.852,3.852,0,0,0,1.647-1.56,8.073,8.073,0,0,0,.69-1.3C25.391,21.828,25.57,21.649,24.766,20.85Z" transform="translate(-4.49 -4.503)" fill="#fff"/>
                </svg>
                </a>
                <a  v-if="isShowInstagram" :href="instagram " target="_blank" class="bongah-filter z-50 relative"><svg xmlns="http://www.w3.org/2000/svg" width="53.883" height="53.878" viewBox="0 0 53.883 53.878">
                  <path  id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M11.939,8.055a6.122,6.122,0,1,0,6.122,6.122A6.112,6.112,0,0,0,11.939,8.055Zm0,10.1a3.98,3.98,0,1,1,3.98-3.98,3.987,3.987,0,0,1-3.98,3.98ZM19.739,7.8a1.428,1.428,0,1,1-1.428-1.428A1.425,1.425,0,0,1,19.739,7.8Zm4.055,1.449a7.066,7.066,0,0,0-1.929-5,7.113,7.113,0,0,0-5-1.929c-1.971-.112-7.88-.112-9.852,0a7.1,7.1,0,0,0-5,1.923,7.09,7.09,0,0,0-1.929,5c-.112,1.971-.112,7.88,0,9.852a7.066,7.066,0,0,0,1.929,5,7.122,7.122,0,0,0,5,1.929c1.971.112,7.88.112,9.852,0a7.066,7.066,0,0,0,5-1.929,7.113,7.113,0,0,0,1.929-5c.112-1.971.112-7.875,0-9.846ZM21.247,21.215a4.029,4.029,0,0,1-2.27,2.27c-1.572.623-5.3.48-7.038.48s-5.472.139-7.038-.48a4.029,4.029,0,0,1-2.27-2.27c-.623-1.572-.48-5.3-.48-7.038s-.139-5.472.48-7.038A4.029,4.029,0,0,1,4.9,4.868c1.572-.623,5.3-.48,7.038-.48s5.472-.139,7.038.48a4.029,4.029,0,0,1,2.27,2.27c.623,1.572.48,5.3.48,7.038S21.87,19.648,21.247,21.215Z" transform="translate(15.005 12.762)" fill="#fff"/>
                </svg>
                </a>
                <a v-if="isShowWhatsApp" :href="`https://wa.me/${whatsApp}`" target="_blank" class="bongah-filter">
                  <svg xmlns="http://www.w3.org/2000/svg" width="54.446" height="54.446" viewBox="0 0 54.446 54.446">
                    <path id="Icon_simple-whatsapp" data-name="Icon simple-whatsapp" d="M17.823,14.649c-.307-.153-1.8-.883-2.078-.984s-.482-.153-.685.153S14.274,14.8,14.1,15s-.355.214-.658.076a8.267,8.267,0,0,1-2.448-1.513A9.241,9.241,0,0,1,9.3,11.457c-.177-.306-.019-.474.132-.626.139-.138.307-.351.459-.533a2.582,2.582,0,0,0,.3-.505.56.56,0,0,0-.025-.534c-.076-.153-.684-1.65-.939-2.247s-.5-.519-.684-.519c-.175-.015-.378-.015-.582-.015a1.148,1.148,0,0,0-.812.366A3.4,3.4,0,0,0,6.089,9.364,5.96,5.96,0,0,0,7.331,12.5a13.582,13.582,0,0,0,5.195,4.568c.727.306,1.294.489,1.736.641a4.214,4.214,0,0,0,1.915.123,3.135,3.135,0,0,0,2.053-1.452,2.521,2.521,0,0,0,.183-1.451c-.075-.138-.275-.214-.581-.351m-5.547,7.581h-.016A10.189,10.189,0,0,1,7.12,20.748l-.367-.218-3.82.993,1.024-3.713-.243-.382A10.1,10.1,0,0,1,19.447,4.967a9.97,9.97,0,0,1,2.963,7.12A10.115,10.115,0,0,1,12.291,22.155M20.9,3.513A12.367,12.367,0,0,0,12.269,0,12.11,12.11,0,0,0,1.727,18.169L0,24.446l6.453-1.683a12.286,12.286,0,0,0,5.816,1.474h.006A12.162,12.162,0,0,0,24.446,12.12a12.013,12.013,0,0,0-3.56-8.567" transform="translate(15 15)" fill="#fff"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isForRealEstate">
          <div class="mt-7 flex justify-between items-center">
            <div class="flex ">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18.854" height="18.854" viewBox="0 0 18.854 18.854">
              <path id="fi-rr-id-badge" d="M14.926,3.142H11.784V2.357a2.357,2.357,0,1,0-4.713,0v.786H3.928A3.933,3.933,0,0,0,0,7.07v7.856a3.933,3.933,0,0,0,3.928,3.928h11a3.933,3.933,0,0,0,3.928-3.928V7.07a3.933,3.933,0,0,0-3.928-3.928ZM8.641,2.357a.786.786,0,0,1,1.571,0V3.928a.786.786,0,1,1-1.571,0Zm8.641,12.569a2.357,2.357,0,0,1-2.357,2.357h-11a2.357,2.357,0,0,1-2.357-2.357V7.07A2.357,2.357,0,0,1,3.928,4.713H7.215S8.43,6.285,9.427,6.285a2.343,2.343,0,0,0,2.212-1.572h3.287A2.357,2.357,0,0,1,17.283,7.07ZM7.856,7.856H3.928a.786.786,0,0,0-.786.786v6.285a.786.786,0,0,0,.786.786H7.856a.786.786,0,0,0,.786-.786V8.641A.786.786,0,0,0,7.856,7.856ZM7.07,14.14H4.713V9.427H7.07Zm8.641-2.357a.786.786,0,0,1-.786.786H11A.786.786,0,0,1,11,11h3.928A.786.786,0,0,1,15.711,11.784Zm0-3.142a.786.786,0,0,1-.786.786H11a.786.786,0,0,1,0-1.571h3.928A.786.786,0,0,1,15.711,8.641ZM14.14,14.926a.786.786,0,0,1-.786.786H11a.786.786,0,0,1,0-1.571h2.357A.786.786,0,0,1,14.14,14.926Z" fill="#ff7400"/>
            </svg>
          </span>
              <p class="text-white text-sm mr-3">جواز کسب  بنگاه</p>
            </div>
            <a :href="baseURL + data.realestate.license_picture"><img class="javaz-box" :src="baseURL + data.realestate.license_picture " alt=""></a>
          </div>
          <router-link :to="{name : 'RealEstatePageCustomer' ,params :{ id : data.real_estate_id}}" class="btn-custom  my-4 w-full">
            اطلاعات بیشتر بنگاه
            <span class="svg-btn-icon bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
          </router-link>
        </div>
        <div v-else>
          <a @click="handleEditAds" class="btn-custom my-4 w-full">
            ویرایش ملک
            <span class="svg-btn-icon2 bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
          </a>
          <a @click="isShowDeleteAds = true" class="btn-custom my-4 w-full">
            حذف ملک
            <span class="svg-btn-icon2 bg-red transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
          </a>
        </div>
      </div>
    </div>
    <div class="delete-ads" v-if="isShowDeleteAds">
      <p class="">مطمعنی میخوای این اگهی رو حذف کنی؟</p>
      <div class="flex gap-2 justify-center mt-8" >
        <a @click="handleDeleteAds" class="btn-delete-accept">اره</a>
        <a @click="isShowDeleteAds = false" class="btn-delete-reject">نه</a>
      </div>
    </div>
    <carousel :media="data.media" @close-carousel="closeCarousel" v-if="isShowCarousel" />
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import {mapir, mapMarker} from "mapir-vue";
import carousel from "../../components/carousel";
export default {
  name: "EstateDetailCustomer",
  components:{
    mapir,
    mapMarker,
    carousel
  },
  data() {
    return {
      data : null,
      baseURL : this.$store.state.general.baseURL,
      mapToken : this.$store.state.map.token,
      marker : null,
      countMedia : 0,
      isShowCarousel : false,
      isForRealEstate : false,
      isShowDeleteAds : false,
      isShowWhatsApp : false,
      isShowInstagram : false,
      whatsApp : '',
      instagram : ''
    }
  },
  methods :{
    closeCarousel(data){
      this.isShowCarousel = data
    },
    handleOpenlink(){
      window.open(data.realestate.socials[0].link , '_blank')
    },
    handleDeleteAds(){
      axios.delete('/estates/'+this.data.id).then(({data})=>{
        this.$toast.success('ملک مورد نطر با موفقیت حذف شد')
        this.$router.push('/real-estate')
      })
    },
    handleEditAds(){
      this.$router.push({name :'EditEstateStep1'});
      this.$store.commit('editEstate/SET_ALL_DATA',this.data)
    }
  },
  async mounted() {
    let id = this.$route.params.id;
    let user = JSON.parse(localStorage.getItem('user'))
    await axios.get('/estates/'+id).then(({data})=>{
      this.data = data.data
      this.marker = [ this.data.geoposition.longitude ,this.data.geoposition.latitude]
      this.countMedia = this.data.media.length;

    });
    for(let i = 0; i < await this.data.realestate.socials.length ; i++){
      let socials = this.data.realestate.socials;
      console.log(socials)
      if (socials[i].string === "whatsapp"){
        this.isShowWhatsApp = true;
        this.whatsApp = socials[i].link
      }
      if (socials[i].string === "instagram"){
        this.isShowInstagram = true;
        this.instagram = socials[i].link
      }
    }
    console.log(this.data.realestate)

  }
}
</script>

<style scoped>
.delete-ads{
  position: fixed;
  width: 90%;
  background: #553A5A;
  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem 1rem;
  color: #fff;
  border-radius: 2rem;
  z-index: 100;
}
.bg-mask-delete{
  height: 100vh;
  width: 100vw;
  background: #361c3beb;
  position: absolute;
  z-index: 100;
}
.btn-delete-accept{
  cursor: pointer;
  border-radius: 1rem;
  padding: 1rem 3rem;
  background: #644B92;
}
.btn-delete-reject{
  cursor: pointer;
  border-radius: 1rem;
  padding: 1rem 3rem;
  background: #DB3C31;
}

</style>